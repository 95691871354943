import React, { MouseEvent, useContext, useEffect, useState } from "react";
import { Row, Col, Typography, App, Space, Tag, AutoComplete, Form, Select, Tooltip } from 'antd';
import { FaPlus } from "react-icons/fa";
import _ from "lodash";
import { API, Enum, Regex } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Interfaces
import { AIFile, ClassificationsForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios";

const { Pages: { Profile: { AIFiles: AIFilesLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_GET_AI_FILE, USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, TENDER_NAICS_CODE_PAGINATE, TENDER_PSC_CODE_PAGINATE, TENDER_SET_ASIDE_CODE_PAGINATE, TENDER_NOTICE_TYPE_PAGINATE, TENDER_BUYER_PAGINATE} = API
const { Message } = Enum
const { isNumber } = Regex

export default function Suggestions(props: { Form: any, form: any, aiFileId: string, setOpenModal: (openModal: boolean) => void }) {
    const { Form, form, aiFileId, setOpenModal } = props;

    const [naicsCodes, setNAICSCodes] = useState<string[]>([])
    const [naicsCodeOptions, setNAICSCodeOptions] = useState<{ label: string, value: string }[]>([])
    const [pscCodes, setPSCCodes] = useState<string[]>([]);
    const [pscCodeOptions, setPSCCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [setAsideCodes, setSetAsideCodes] = useState<{ id: string, name: string }[]>([]);
    const [noticeTypes, setNoticeTypes] = useState<{ id: string, name: string }[]>([]);
    const [buyerIncludeOptions, setBuyerIncludeOptions] = useState<{ name: string }[]>([]);
    const [buyersInclude, setBuyersInclude] = useState<string[]>([]);
    const [buyerExcludeOptions, setBuyerExcludeOptions] = useState<{ name: string }[]>([]);
    const [buyersExclude, setBuyersExclude] = useState<string[]>([]);
    const [aiFile, setAIFile] = useState<AIFile|null>(null)
    const { axios } = useContext(AxiosContext)
    const { message } = App.useApp()

    useEffect(() => {
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getAIFile()
        await getClassifications()
    }

    const getAIFile = () => axios.get(USER_MANAGEMENT_PROFILE_GET_AI_FILE + aiFileId).then((response: { data: AIFile }) => {
        const { data: aiFile } = response
        setAIFile(aiFile)
    }, (error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]).then(response => response)
    })

    const getClassifications = async () => axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION).then(async (response: { data: ClassificationsForm }) => {
        const { data: { naicsCodes = [], pscCodes = [], buyersInclude = [], buyersExclude = [], setAsideCodes: responseSetAsideCodes = [], noticeTypes: responseNoticeTypes = [] } } = response
        form.resetFields();
        setBuyerIncludeOptions([])
        setBuyerExcludeOptions([])
        setNAICSCodeOptions([])
        setPSCCodeOptions([])
        setNAICSCodes(naicsCodes);
        setPSCCodes(pscCodes);
        setBuyersInclude(buyersInclude);
        setBuyersExclude(buyersExclude);
        await getSetAsideCodes(responseSetAsideCodes);
        await getNoticeTypes(responseNoticeTypes);
    }).catch( (error: {}) => error )

    const getNoticeTypes = async (responseNoticeTypes: string[]) => axios.post(TENDER_NOTICE_TYPE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: noticeTypes, total} } = response
        setNoticeTypes(noticeTypes);
        form.setFieldsValue({ noticeTypes: responseNoticeTypes.map((noticeType: string) => _.findLast(noticeTypes, { name: noticeType })?.id) });
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getSetAsideCodes = async (responseSetAsideCodes: string[]) => axios.post(TENDER_SET_ASIDE_CODE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: setAsideCodes, total} } = response
        setSetAsideCodes(setAsideCodes);
        form.setFieldsValue({ setAsideCodes: responseSetAsideCodes.map((setAsideCode: string) => _.findLast(setAsideCodes, { name: setAsideCode })?.id) });
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const setBuyerInclude = (buyerInclude: string) => {
        if (buyerInclude) {
            setBuyersInclude(_.uniq([...buyersInclude, buyerInclude]));
            form.resetFields(['buyerInclude']);
        }
        setBuyerIncludeOptions([])
    }

    const setBuyerExclude = (buyerExclude: string) => {
        if (buyerExclude) {
            setBuyersExclude(_.uniq([...buyersExclude, buyerExclude]));
            form.resetFields(['buyerExclude']);
        }
        setBuyerExcludeOptions([])
    }

    const setNAICSCode = (naicsCode: string) => {
        if (naicsCode && naicsCode.match(isNumber)) {
            setNAICSCodes(_.uniq([...naicsCodes, naicsCode]));
            form.resetFields(['naicsCode']);
        }
        setNAICSCodeOptions([])
    }

    const setPSCCode = (pscCode: string) => {
        if (pscCode) {
            setPSCCodes(_.uniq([...pscCodes, pscCode]));
            form.resetFields(['pscCode']);
        }
        setPSCCodeOptions([])
    }

    const onFinish = (values: ClassificationsForm) => {
        const body: ClassificationsForm = {
            buyersInclude,
            buyersExclude,
            naicsCodes,
            pscCodes,
        };
        // @ts-ignore
        if (values.setAsideCodes) body.setAsideCodes = values.setAsideCodes.map((setAsideCode: string) => _.findLast(setAsideCodes, { id: setAsideCode })?.name)
        // @ts-ignore
        if (values.noticeTypes) body.noticeTypes = values.noticeTypes.map((noticeType: string) => _.findLast(noticeTypes, { id: noticeType })?.name)
        axios.post(USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, body).then(async (response: {}) => {
            await getClassifications()
            message.success(MessageLanguage[Message.CHANGE_SUCCESS]).then(response => response)
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <Space direction="vertical" style={{width:'100%'}}>
            <Form layout={'vertical'} form={form} name="suggestions" autoComplete="off" onFinish={onFinish}>
                <Form.Item
                    label={AIFilesLanguage.naicsCode}
                    name="naicsCode"
                    rules={[
                        { required: !naicsCodes.length, message: `${AIFilesLanguage.naicsCode} ${Validation.required}` },
                        { pattern: isNumber, message: `${AIFilesLanguage.naicsCode} ${Validation.number}` },
                    ]}
                >
                    <AutoComplete
                        size="large"
                        placeholder={AIFilesLanguage.naicsCodePlaceholder}
                        options={naicsCodeOptions}
                        onSearch={(code) => axios.post(TENDER_NAICS_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                            const { data: { items, total } } = response
                            if (code) setNAICSCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                            else setNAICSCodeOptions([])
                        }).catch( (error: {}) => setNAICSCodeOptions([]) )}
                        onSelect={(naicsCode: string) => setNAICSCode(naicsCode)}
                        onBlur={({ target: { value: naicsCode } }: any) => setNAICSCode(naicsCode)}
                    />
                </Form.Item>
                <Row gutter={20}>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.naicsCodeSelected}</Typography.Title>
                            <div className={'tags'}>
                                {naicsCodes.map((naicsCode: string) => <Tag key={naicsCode} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const naicsCodesCopy = [...naicsCodes]
                                    naicsCodesCopy.splice(naicsCodesCopy.indexOf(naicsCode), 1)
                                    setNAICSCodes(naicsCodesCopy)
                                }}>{naicsCode}</Tag>)}
                            </div>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.naicsCodeSuggested}</Typography.Title>
                            <div className={'tags'}>
                                { aiFile?.ai?.naicsCode && <Tag color="#e1e6e8"><Space align={'center'}><span>{aiFile?.ai?.naicsCode}</span><FaPlus className={'tag-add'} size={13} onClick={(event: MouseEvent) => aiFile?.ai?.naicsCode && setNAICSCode(aiFile?.ai?.naicsCode)}/></Space></Tag> }
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Form.Item
                    label={AIFilesLanguage.pscCode}
                    name="pscCode"
                    style={{marginTop:20}}
                >
                    <AutoComplete
                        size={'large'}
                        options={pscCodeOptions}
                        placeholder={AIFilesLanguage.pscCodePlaceholder}
                        onSearch={(code) => axios.post(TENDER_PSC_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                            const { data: { items, total } } = response
                            if (code) setPSCCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                            else setPSCCodeOptions([])
                        }).catch( (error: {}) => setPSCCodeOptions([]) )}
                        onSelect={(pscCode: string) => setPSCCode(pscCode)}
                        onBlur={({ target: { value: pscCode } }: any) => setPSCCode(pscCode)}
                    />
                </Form.Item>
                <Row gutter={20}>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.pscCodeSelected}</Typography.Title>
                            <div className={'tags'}>
                                {pscCodes.map((pscCode: string) => <Tag key={pscCode} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const pscCodesCopy = [...pscCodes]
                                    pscCodesCopy.splice(pscCodesCopy.indexOf(pscCode), 1)
                                    setPSCCodes(pscCodesCopy)
                                }}>{pscCode}</Tag>)}
                            </div>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.pscCodeSuggested}</Typography.Title>
                            <div className={'tags'}>
                                { aiFile?.ai?.pscCode && <Tag color="#e1e6e8"><Space align={'center'}><span>{aiFile?.ai?.pscCode}</span><FaPlus className={'tag-add'} size={13} onClick={(event: MouseEvent) => aiFile?.ai?.pscCode && setPSCCode(aiFile?.ai?.pscCode)}/></Space></Tag> }
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Form.Item
                    label={AIFilesLanguage.setAsideCode}
                    name="setAsideCodes"
                    style={{marginTop:20}}
                >
                    <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        placeholder={AIFilesLanguage.setAsideCodePlaceholder}
                        size={'large'}
                        optionFilterProp="label"
                        options={setAsideCodes.map(({ id, name }) => ({ label: name, value: id }))}
                    />
                </Form.Item>
                <Space direction={'vertical'} style={{width:'100%'}}>
                    <Typography.Title level={2}>{AIFilesLanguage.setAsideCodeSuggested}</Typography.Title>
                    <div className={'tags'}>
                        { aiFile?.ai?.setAside && _.isArray(aiFile?.ai?.setAside) && aiFile?.ai?.setAside.map((item, key) => <Tooltip key={key} placement="top" title={item}><Tag color="#e1e6e8"><span className={'ellipsis'}>{item}</span></Tag></Tooltip>) }
                    </div>
                </Space>
                <Form.Item
                    label={AIFilesLanguage.noticeType}
                    name="noticeTypes"
                    style={{marginTop:20}}
                >
                    <Select
                        showSearch
                        allowClear
                        placeholder={AIFilesLanguage.noticeTypePlaceholder}
                        mode="multiple"
                        size={'large'}
                        optionFilterProp="label"
                        options={noticeTypes.map(({ id, name }) => ({ label: name, value: id }))}
                    />
                </Form.Item>
                <Space direction={'vertical'} style={{width:'100%'}}>
                    <Typography.Title level={2}>{AIFilesLanguage.noticeTypeSuggested}</Typography.Title>
                    <div className={'tags'}>
                        { aiFile?.ai?.noticeType && _.isArray(aiFile?.ai?.noticeType) && aiFile?.ai?.noticeType.map((item, key) => <Tooltip key={key} placement="top" title={item}><Tag color="#e1e6e8"><span className={'ellipsis'}>{item}</span></Tag></Tooltip>) }
                    </div>
                </Space>
                <Form.Item
                    label={AIFilesLanguage.buyerInclude}
                    name="buyerInclude"
                    style={{marginTop:20}}
                >
                    <AutoComplete
                        placeholder={AIFilesLanguage.buyerIncludePlaceholder}
                        size="large"
                        options={buyerIncludeOptions.map(({ name }) => ({ label: (
                                <Space direction={'vertical'} size={0}>
                                    <Typography.Title level={3}>{name}</Typography.Title>
                                </Space>
                            ), value: name }))}
                        onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                            const { data: { items, total } } = response
                            if (name) setBuyerIncludeOptions(items.map(({ name}) => ({ name })))
                            else setBuyerIncludeOptions([])
                        }).catch( (error: {}) => setBuyerIncludeOptions([]) )}
                        onSelect={(buyerInclude: string) => setBuyerInclude(buyerInclude)}
                        onBlur={({ target: { value: buyerInclude } }: any) => setBuyerInclude(buyerInclude)}
                    />
                </Form.Item>
                <Row gutter={20}>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.buyersIncludeSelected}</Typography.Title>
                            <div className={'tags'}>
                                {buyersInclude.map((buyerInclude: string) => <Tooltip key={buyerInclude} placement="top" title={buyerInclude}><Tag color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const buyersIncludeCopy = [...buyersInclude]
                                    buyersIncludeCopy.splice(buyersIncludeCopy.indexOf(buyerInclude), 1)
                                    setPSCCodes(buyersIncludeCopy)
                                }}><span className={'ellipsis'}>{buyerInclude}</span></Tag></Tooltip>)}
                            </div>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.buyerIncludeSuggested}</Typography.Title>
                            <div className={'tags'}>
                                { aiFile?.ai?.agency && <Tooltip placement="top" title={aiFile?.ai?.agency}><Tag color="#e1e6e8"><Space align={'center'}><span className={'ellipsis'}>{aiFile?.ai?.agency}</span><FaPlus className={'tag-add'} size={13} onClick={(event: MouseEvent) => aiFile?.ai?.agency && setBuyerInclude(aiFile?.ai?.agency)}/></Space></Tag></Tooltip> }
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Form.Item
                    label={AIFilesLanguage.buyerExclude}
                    name="buyerExclude"
                    style={{marginTop:20}}
                >
                    <AutoComplete
                        placeholder={AIFilesLanguage.buyerExcludePlaceholder}
                        size="large"
                        options={buyerExcludeOptions.map(({ name }) => ({ label: (
                                <Space direction={'vertical'} size={0}>
                                    <Typography.Title level={3}>{name}</Typography.Title>
                                </Space>
                            ), value: name }))}
                        onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                            const { data: { items, total } } = response
                            if (name) setBuyerExcludeOptions(items.map(({ name}) => ({ name })))
                            else setBuyerExcludeOptions([])
                        }).catch( (error: {}) => setBuyerExcludeOptions([]) )}
                        onSelect={(buyerExclude: string) => setBuyerExclude(buyerExclude)}
                        onBlur={({ target: { value: buyerExclude } }: any) => setBuyerExclude(buyerExclude)}
                    />
                </Form.Item>
                <Row gutter={20}>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.buyersExcludeSelected}</Typography.Title>
                            <div className={'tags'}>
                                {buyersExclude.map((buyerExclude: string) => <Tooltip key={buyerExclude} placement="top" title={buyerExclude}><Tag color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const buyersExcludeCopy = [...buyersExclude]
                                    buyersExcludeCopy.splice(buyersExcludeCopy.indexOf(buyerExclude), 1)
                                    setPSCCodes(buyersExcludeCopy)
                                }}><span className={'ellipsis'}>{buyerExclude}</span></Tag></Tooltip>)}
                            </div>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} lg={24} xl={12}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Typography.Title level={2}>{AIFilesLanguage.buyerExcludeSuggested}</Typography.Title>
                            <div className={'tags'}>
                                { aiFile?.ai?.agency && <Tooltip placement="top" title={aiFile?.ai?.agency}><Tag color="#e1e6e8"><Space align={'center'}><span className={'ellipsis'}>{aiFile?.ai?.agency}</span><FaPlus className={'tag-add'} size={13} onClick={(event: MouseEvent) => aiFile?.ai?.agency && setBuyerExclude(aiFile?.ai?.agency)}/></Space></Tag></Tooltip> }
                            </div>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Space>
    )
}
