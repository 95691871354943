import React, { useContext, useEffect, useState } from 'react'
import { Space, Typography, Form, App, Input, Button, Select } from 'antd'
import { Helmet } from "react-helmet"
import { useNavigate } from 'react-router-dom'
import validator from "validator"
import { FaUserAlt, FaEnvelope, FaMapMarker } from "react-icons/fa";
import { API, Enum, ActionType, Regex } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { OverviewForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Profile: { Overview: OverviewLanguage }  }, Share, Validation, Message: MessageLanguage } = language
const { SET_USER } = ActionType
const { USER_MANAGEMENT_PROFILE_GET_OVERVIEW, USER_MANAGEMENT_PROFILE_SET_OVERVIEW, USER_MANAGEMENT_PROFILE_MY_ACCOUNT, BASIC_INFORMATION_COUNTRY_ALL } = API
const { Message } = Enum
const { isNumber, isWebsite } = Regex

export default function Overview() {

    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const { axios } = useContext(AxiosContext)
    const { user, dispatch } = useContext(GlobalContext)
    const [countries, setCountries] = useState<{ id: string, name: string }[]>([]);
    const { message } = App.useApp()
    const navigate = useNavigate()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getCountries()
        await getOverview()
    }

    const getCountries = () => axios.post(BASIC_INFORMATION_COUNTRY_ALL).then((response: { data: { id: string, name: string }[] }) => {
        const { data: countries } = response
        setCountries(countries.map(({ id, name }) => ({ id, name })))
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getOverview = async () => axios.get(USER_MANAGEMENT_PROFILE_GET_OVERVIEW).then((response: { data: {
            country: { id: string }
        } }) => {
        const { data: overview } = response
        form.setFieldsValue({ ...overview, country: overview?.country?.id })
    }).catch( (error: {}) => error )

    const onFinish = (values: OverviewForm) => {
        const body: OverviewForm = {};
        if (values.organizationName) body.organizationName = values.organizationName
        if (values.website) body.website = values.website
        if (values.description) body.description = values.description
        if (values.uei) body.uei = values.uei
        if (values.cageCode) body.cageCode = values.cageCode
        if (values.firstName) body.firstName = values.firstName
        if (values.lastName) body.lastName = values.lastName
        if (values.email) body.email = values.email
        if (values.address1) body.address1 = values.address1
        if (values.address2) body.address2 = values.address2
        if (values.city) body.city = values.city
        if (values.state) body.state = values.state
        if (values.zipCode) body.zipCode = values.zipCode
        if (values.country) body.country = values.country
        axios.post(USER_MANAGEMENT_PROFILE_SET_OVERVIEW, body).then((response: {}) => {
            axios.get(USER_MANAGEMENT_PROFILE_MY_ACCOUNT).then((response: { data: {} }) => {
                const { data: user } = response
                dispatch({ type: SET_USER , payload: { user }})
                navigate('/profile/classifications')
                message.success(MessageLanguage[Message.CHANGE_SUCCESS]).then(response => response)
            }).catch( (error: {}) => error )
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <div className={'overview'}>
            <Helmet>
                <title>{title} - {OverviewLanguage.title}</title>
            </Helmet>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{OverviewLanguage.title}</Typography.Title>
                <Form layout={'vertical'} form={form} name="overview" autoComplete="off" onFinish={onFinish} initialValues={{
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                }}>
                    <Form.Item
                        label={OverviewLanguage.organizationName}
                        name="organizationName"
                        rules={[
                            { required: true, message: `${OverviewLanguage.organizationName} ${Validation.required}` },
                            { min: 3, message: `${OverviewLanguage.organizationName} ${Validation.min} 3 ${Validation.length}` },
                            { max: 90, message: `${OverviewLanguage.organizationName} ${Validation.max} 90 ${Validation.length}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.organizationNamePlaceholder} maxLength={90} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.yourWebsite}
                        name="website"
                        rules={[
                            { required: true, message: `${OverviewLanguage.yourWebsite} ${Validation.required}` },
                            { pattern: isWebsite, message: `${OverviewLanguage.yourWebsite} ${Validation.website}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.yourWebsitePlaceholder} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.description}
                        name="description"
                    >
                        <Input.TextArea placeholder={OverviewLanguage.descriptionPlaceholder} rows={6} maxLength={500} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.uei}
                        name="uei"
                        rules={[
                            { required: true, message: `${OverviewLanguage.uei} ${Validation.required}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.ueiPlaceholder}/>
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.cageCode}
                        name="cageCode"
                        rules={[
                            { pattern: isNumber, message: `${OverviewLanguage.cageCode} ${Validation.number}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.cageCodePlaceholder}/>
                    </Form.Item>
                    <Typography.Title level={2}>{OverviewLanguage.contactInformation}</Typography.Title>
                    <Form.Item
                        label={OverviewLanguage.firstName}
                        name="firstName"
                        rules={[
                            { required: true, message: `${OverviewLanguage.firstName} ${Validation.required}` },
                            { min: 3, message: `${OverviewLanguage.firstName} ${Validation.min} 3 ${Validation.length}` },
                            { max: 30, message: `${OverviewLanguage.firstName} ${Validation.max} 30 ${Validation.length}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.firstNamePlaceholder} prefix={<FaUserAlt />} maxLength={30} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.lastName}
                        name="lastName"
                        rules={[
                            { required: true, message: `${OverviewLanguage.lastName} ${Validation.required}` },
                            { min: 3, message: `${OverviewLanguage.lastName} ${Validation.min} 3 ${Validation.length}` },
                            { max: 30, message: `${OverviewLanguage.lastName} ${Validation.max} 30 ${Validation.length}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.lastNamePlaceholder} prefix={<FaUserAlt />} maxLength={30} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.email}
                        name="email"
                        rules={[
                            { required: true, message: `${OverviewLanguage.email} ${Validation.required}` },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || validator.isEmail(value)) return Promise.resolve()
                                    else return Promise.reject(new Error(Validation.invalidEmail))
                                },
                            }),
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.emailPlaceholder} prefix={<FaEnvelope />} />
                    </Form.Item>
                    <Typography.Title level={2}>{OverviewLanguage.address}</Typography.Title>
                    <Form.Item
                        label={OverviewLanguage.address1}
                        name="address1"
                        rules={[
                            { required: true, message: `${OverviewLanguage.address1} ${Validation.required}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.address1Placeholder} prefix={<FaMapMarker />} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.address2}
                        name="address2"
                    >
                        <Input placeholder={OverviewLanguage.address2Placeholder} prefix={<FaMapMarker />} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.city}
                        name="city"
                        rules={[
                            { required: true, message: `${OverviewLanguage.city} ${Validation.required}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.cityPlaceholder} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.state}
                        name="state"
                        rules={[
                            { required: true, message: `${OverviewLanguage.state} ${Validation.required}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.statePlaceholder} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.zipCode}
                        name="zipCode"
                        rules={[
                            { required: true, message: `${OverviewLanguage.zipCode} ${Validation.required}` },
                            { pattern: isNumber, message: `${OverviewLanguage.zipCode} ${Validation.number}` },
                        ]}
                    >
                        <Input placeholder={OverviewLanguage.zipCodePlaceholder} />
                    </Form.Item>
                    <Form.Item
                        label={OverviewLanguage.country}
                        name="country"
                        rules={[
                            { required: true, message: `${OverviewLanguage.country} ${Validation.required}` },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            size={'large'}
                            optionFilterProp="label"
                            placeholder={OverviewLanguage.countryPlaceholder}
                            options={countries.map(({ id, name }) => ({ label: name, value: id }))}
                        />
                    </Form.Item>
                    <Form.Item style={{marginTop:50}} shouldUpdate>
                        {() => (
                            <Button
                                type={'primary'}
                                htmlType="submit"
                                block
                            >
                                {OverviewLanguage.saveChange}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Space>
        </div>
    )
}
