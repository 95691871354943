import React, { useContext, useEffect, useState } from 'react'
import { Space, Typography, Flex, Button, Table, App } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { Helmet } from "react-helmet"
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { API } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { Payment } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"
import type { ColumnsType } from 'antd/es/table'

// Contexts
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Profile: { PaymentHistory: PaymentHistoryLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_INVOICE_ALL} = API

export default function PaymentHistory() {

    const [paymentHistories, setPaymentHistories] = useState<Payment[]>([])
    const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
    const { axios } = useContext(AxiosContext)
    const { message } = App.useApp()
    const navigate = useNavigate()

    const columns: ColumnsType<Payment> = [
        {
            title: PaymentHistoryLanguage.plan,
            dataIndex: 'plan',
            key: 'plan',
            sorter: true,
            // @ts-ignore
            render: (text, record) => EnumLanguage.Plan[text]
        },
        {
            title: PaymentHistoryLanguage.activation,
            dataIndex: 'activationAt',
            key: 'activationAt',
            sorter: true,
            render: (text, record) => text && dayjs(text).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')
        },
        {
            title: PaymentHistoryLanguage.amount,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (text, record) => text && (
                <Space size={10} align={'baseline'}>
                    <FaCheckCircle color={'#23d145'} size={16} />
                    <b>{Share.usd}</b>
                    <span>{(text/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </Space>
            )
        },
        {
            title: PaymentHistoryLanguage.expiration,
            dataIndex: 'expirationAt',
            key: 'expirationAt',
            sorter: true,
            render: (text, record) => text && dayjs(text).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')
        },
    ]

    useEffect(() => {
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getPaymentHistory()
    }

    const getPaymentHistory = async () => {
        setLoadingRecords(true)
        axios.get(USER_MANAGEMENT_PROFILE_INVOICE_ALL).then((response: { data: Payment[] }) => {
            const { data: paymentHistories } = response
            setPaymentHistories(paymentHistories)
            setLoadingRecords(false)
        }, async (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response )
            setLoadingRecords(false)
        })
    }

    return (
        <div className={'payment-history'}>
            <Helmet>
                <title>{title} - {PaymentHistoryLanguage.title}</title>
            </Helmet>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{PaymentHistoryLanguage.title}</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={paymentHistories}
                    pagination={false}
                    loading={loadingRecords}
                    scroll={{ x: true }}
                />
                <Flex gap={'middle'} justify={'end'}>
                    <Button
                        type="primary"
                        block
                        onClick={() => navigate('/')}
                    >
                        {PaymentHistoryLanguage.backOpportunities}
                    </Button>
                </Flex>
            </Space>
        </div>
    )
}
