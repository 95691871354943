import React, { useContext, useEffect, useState, MouseEvent } from 'react'
import { Space, Typography, Form, App, Button, AutoComplete, Tag, Select, Radio } from 'antd'
import { Helmet } from "react-helmet"
import _ from "lodash";
import { useNavigate } from 'react-router-dom'
import { API, Enum, Regex } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { ClassificationsForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Profile: { Classifications: ClassificationsLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, TENDER_NAICS_CODE_PAGINATE, TENDER_PSC_CODE_PAGINATE, TENDER_SET_ASIDE_CODE_PAGINATE, TENDER_NOTICE_TYPE_PAGINATE, TENDER_BUYER_PAGINATE } = API
const { Message, Job, CompanySize, TenderType } = Enum
const { isNumber } = Regex

export default function Classifications() {

    const { sources: opportunities } = useContext(GlobalContext)
    const [naicsCodes, setNAICSCodes] = useState<string[]>([]);
    const [pscCodes, setPSCCodes] = useState<string[]>([]);
    const [naicsCodeOptions, setNAICSCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [pscCodeOptions, setPSCCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [setAsideCodes, setSetAsideCodes] = useState<{ id: string, name: string }[]>([]);
    const [noticeTypes, setNoticeTypes] = useState<{ id: string, name: string }[]>([]);
    const [buyerIncludeOptions, setBuyerIncludeOptions] = useState<{ name: string }[]>([]);
    const [buyersInclude, setBuyersInclude] = useState<string[]>([]);
    const [buyerExcludeOptions, setBuyerExcludeOptions] = useState<{ name: string }[]>([]);
    const [buyersExclude, setBuyersExclude] = useState<string[]>([]);
    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const { axios } = useContext(AxiosContext)
    const navigate = useNavigate()
    const { message } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getClassifications()
    }

    const getClassifications = async () => axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION).then(async (response: { data: ClassificationsForm }) => {
        const { data: { naicsCodes = [], pscCodes = [], buyersInclude = [], buyersExclude = [], setAsideCodes: responseSetAsideCodes = [], opportunities: responseOpportunities = [], noticeTypes: responseNoticeTypes = [], jobs, companySize, tenderTypes} } = response
        form.resetFields();
        setBuyerIncludeOptions([])
        setBuyerExcludeOptions([])
        setNAICSCodeOptions([])
        setPSCCodeOptions([])
        setNAICSCodes(naicsCodes);
        setPSCCodes(pscCodes);
        setBuyersInclude(buyersInclude);
        setBuyersExclude(buyersExclude);
        await getSetAsideCodes(responseSetAsideCodes);
        await getNoticeTypes(responseNoticeTypes);
        responseOpportunities && form.setFieldValue('opportunities', responseOpportunities)
        jobs && form.setFieldValue('job', jobs[0])
        companySize && form.setFieldValue('companySize', companySize)
        tenderTypes && form.setFieldValue('tenderType', tenderTypes[0])
    }).catch( (error: {}) => error )

    const getNoticeTypes = async (responseNoticeTypes: string[]) => axios.post(TENDER_NOTICE_TYPE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: noticeTypes, total} } = response
        setNoticeTypes(noticeTypes);
        form.setFieldsValue({ noticeTypes: responseNoticeTypes.map((noticeType: string) => _.findLast(noticeTypes, { name: noticeType })?.id) });
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getSetAsideCodes = async (responseSetAsideCodes: string[]) => axios.post(TENDER_SET_ASIDE_CODE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: setAsideCodes, total} } = response
        setSetAsideCodes(setAsideCodes);
        form.setFieldsValue({ setAsideCodes: responseSetAsideCodes.map((setAsideCode: string) => _.findLast(setAsideCodes, { name: setAsideCode })?.id) });
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const setBuyerInclude = (buyerInclude: string) => {
        if (buyerInclude) {
            setBuyersInclude(_.uniq([...buyersInclude, buyerInclude]));
            form.resetFields(['buyerInclude']);
        }
        setBuyerIncludeOptions([])
    }

    const setBuyerExclude = (buyerExclude: string) => {
        if (buyerExclude) {
            setBuyersExclude(_.uniq([...buyersExclude, buyerExclude]));
            form.resetFields(['buyerExclude']);
        }
        setBuyerExcludeOptions([])
    }

    const setNAICSCode = (naicsCode: string) => {
        if (naicsCode && naicsCode.match(isNumber)) {
            setNAICSCodes(_.uniq([...naicsCodes, naicsCode]));
            form.resetFields(['naicsCode']);
        }
        setNAICSCodeOptions([])
    }

    const setPSCCode = (pscCode: string) => {
        if (pscCode) {
            setPSCCodes(_.uniq([...pscCodes, pscCode]));
            form.resetFields(['pscCode']);
        }
        setPSCCodeOptions([])
    }

    const onFinish = (values: ClassificationsForm) => {
        const body: ClassificationsForm = {
            buyersInclude,
            buyersExclude,
            naicsCodes,
            pscCodes,
        };
        // @ts-ignore
        if (values.setAsideCodes) body.setAsideCodes = values.setAsideCodes.map((setAsideCode: string) => _.findLast(setAsideCodes, { id: setAsideCode })?.name)
        if (values.opportunities) body.opportunities = values.opportunities
        // @ts-ignore
        if (values.noticeTypes) body.noticeTypes = values.noticeTypes.map((noticeType: string) => _.findLast(noticeTypes, { id: noticeType })?.name)
        if (values.job) body.jobs = [values.job]
        if (values.companySize) body.companySize = values.companySize
        if (values.tenderType) body.tenderTypes = [values.tenderType]
        axios.post(USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION, body).then(async (response: {}) => {
            await getClassifications()
            // navigate('/profile/ai-files')
            message.success(MessageLanguage[Message.CHANGE_SUCCESS]).then(response => response)
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <div className={'classifications'}>
            <Helmet>
                <title>{title} - {ClassificationsLanguage.title}</title>
            </Helmet>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{ClassificationsLanguage.title}</Typography.Title>
                <Form layout={'vertical'} form={form} name="classifications" autoComplete="off" onFinish={onFinish}>
                    <Form.Item
                        label={ClassificationsLanguage.naicsCode}
                        name="naicsCode"
                        rules={[
                            { pattern: isNumber, message: `${ClassificationsLanguage.naicsCode} ${Validation.number}` },
                        ]}
                        extra={!_.isEmpty(naicsCodes) && (
                            <div className={'tags'}>
                                {naicsCodes.map((naicsCode: string) => <Tag key={naicsCode} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const naicsCodesCopy = [...naicsCodes]
                                    naicsCodesCopy.splice(naicsCodesCopy.indexOf(naicsCode), 1)
                                    setNAICSCodes(naicsCodesCopy)
                                }}>{naicsCode}</Tag>)}
                            </div>
                        )}
                    >
                        <AutoComplete
                            size="large"
                            options={naicsCodeOptions}
                            placeholder={ClassificationsLanguage.naicsCodePlaceholder}
                            onSearch={(code) => axios.post(TENDER_NAICS_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                const { data: { items, total } } = response
                                if (code) setNAICSCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                else setNAICSCodeOptions([])
                            }).catch( (error: {}) => setNAICSCodeOptions([]) )}
                            onSelect={(naicsCode: string) => setNAICSCode(naicsCode)}
                            onBlur={({ target: { value: naicsCode } }: any) => setNAICSCode(naicsCode)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.pscCode}
                        name="pscCode"
                        extra={!_.isEmpty(pscCodes) && (
                            <div className={'tags'}>
                                {pscCodes.map((pscCode: string) => <Tag key={pscCode} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const pscCodesCopy = [...pscCodes]
                                    pscCodesCopy.splice(pscCodesCopy.indexOf(pscCode), 1)
                                    setPSCCodes(pscCodesCopy)
                                }}>{pscCode}</Tag>)}
                            </div>
                        )}
                    >
                        <AutoComplete
                            size={'large'}
                            options={pscCodeOptions}
                            placeholder={ClassificationsLanguage.pscCodePlaceholder}
                            onSearch={(code) => axios.post(TENDER_PSC_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                const { data: { items, total } } = response
                                if (code) setPSCCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                else setPSCCodeOptions([])
                            }).catch( (error: {}) => setPSCCodeOptions([]) )}
                            onSelect={(pscCode: string) => setPSCCode(pscCode)}
                            onBlur={({ target: { value: pscCode } }: any) => setPSCCode(pscCode)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.setAsideCode}
                        name="setAsideCodes"
                    >
                        <Select
                            showSearch
                            allowClear
                            mode="multiple"
                            placeholder={ClassificationsLanguage.setAsideCodePlaceholder}
                            size={'large'}
                            optionFilterProp="label"
                            options={setAsideCodes.map(({ id, name }) => ({ label: name, value: id }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.noticeType}
                        name="noticeTypes"
                    >
                        <Select
                            showSearch
                            allowClear
                            placeholder={ClassificationsLanguage.noticeTypePlaceholder}
                            mode="multiple"
                            size={'large'}
                            optionFilterProp="label"
                            options={noticeTypes.map(({ id, name }) => ({ label: name, value: id }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.opportunity}
                        name="opportunities"
                    >
                        <Select
                            showSearch
                            allowClear
                            placeholder={Share.pleaseSelect}
                            mode="multiple"
                            size={'large'}
                            optionFilterProp="label"
                            options={opportunities.map(({ id, name, color, key }) => ({ label: <Space size={10}><div style={{width:10,height:10,borderRadius:3,backgroundColor:color}}></div><span>{name}</span></Space>, value: key }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.buyer}
                        name="buyerInclude"
                        extra={!_.isEmpty(buyersInclude) && (
                            <div className={'tags'}>
                                {buyersInclude.map((buyerInclude: string) => <Tag key={buyerInclude} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const buyersIncludeCopy = [...buyersInclude]
                                    buyersIncludeCopy.splice(buyersIncludeCopy.indexOf(buyerInclude), 1)
                                    setBuyersInclude(buyersIncludeCopy)
                                }}><span className={'ellipsis'}>{buyerInclude}</span></Tag>)}
                            </div>
                        )}
                    >
                        <AutoComplete
                            placeholder={ClassificationsLanguage.buyerIncludePlaceholder}
                            size="large"
                            options={buyerIncludeOptions.map(({ name }) => ({ label: (
                                    <Space direction={'vertical'} size={0}>
                                        <Typography.Title level={3}>{name}</Typography.Title>
                                    </Space>
                                ), value: name }))}
                            onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                                const { data: { items, total } } = response
                                if (name) setBuyerIncludeOptions(items.map(({ name}) => ({ name })))
                                else setBuyerIncludeOptions([])
                            }).catch( (error: {}) => setBuyerIncludeOptions([]) )}
                            onSelect={(buyerInclude: string) => setBuyerInclude(buyerInclude)}
                            onBlur={({ target: { value: buyerInclude } }: any) => setBuyerInclude(buyerInclude)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="buyerExclude"
                        extra={!_.isEmpty(buyersExclude) && (
                            <div className={'tags'}>
                                {buyersExclude.map((buyerExclude: string) => <Tag key={buyerExclude} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                    const buyersExcludeCopy = [...buyersExclude]
                                    buyersExcludeCopy.splice(buyersExcludeCopy.indexOf(buyerExclude), 1)
                                    setBuyersExclude(buyersExcludeCopy)
                                }}><span className={'ellipsis'}>{buyerExclude}</span></Tag>)}
                            </div>
                        )}
                    >
                        <AutoComplete
                            placeholder={ClassificationsLanguage.buyerExcludePlaceholder}
                            size="large"
                            options={buyerExcludeOptions.map(({ name }) => ({ label: (
                                    <Space direction={'vertical'} size={0}>
                                        <Typography.Title level={3}>{name}</Typography.Title>
                                    </Space>
                                ), value: name }))}
                            onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                                const { data: { items, total } } = response
                                if (name) setBuyerExcludeOptions(items.map(({ name }) => ({ name })))
                                else setBuyerExcludeOptions([])
                            }).catch( (error: {}) => setBuyerExcludeOptions([]) )}
                            onSelect={(buyerExclude: string) => setBuyerExclude(buyerExclude)}
                            onBlur={({ target: { value: buyerExclude } }: any) => setBuyerExclude(buyerExclude)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.job}
                        name="job"
                        >
                        <Radio.Group>
                            <Radio value={Job.Manufacturer}>{EnumLanguage.Job[Job.Manufacturer]}</Radio>
                            <Radio value={Job.Trader}>{EnumLanguage.Job[Job.Trader]}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.companySize}
                        name="companySize"
                    >
                        <Radio.Group>
                            <Radio value={CompanySize.Zero_Ten}>{EnumLanguage.CompanySize[CompanySize.Zero_Ten]}</Radio>
                            <Radio value={CompanySize.Ten_Fifty}>{EnumLanguage.CompanySize[CompanySize.Ten_Fifty]}</Radio>
                            <Radio value={CompanySize.Fifty_Plus}>{EnumLanguage.CompanySize[CompanySize.Fifty_Plus]}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={ClassificationsLanguage.tenderType}
                        name="tenderType"
                    >
                        <Radio.Group>
                            <Radio value={TenderType.Goods}>{EnumLanguage.TenderType[TenderType.Goods]}</Radio>
                            <Radio value={TenderType.Service}>{EnumLanguage.TenderType[TenderType.Service]}</Radio>
                            <Radio value={TenderType.Both}>{EnumLanguage.TenderType[TenderType.Both]}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                disabled={
                                    _.isEmpty(buyersInclude) &&
                                    _.isEmpty(buyersExclude) &&
                                    _.isEmpty(naicsCodes) &&
                                    _.isEmpty(pscCodes) &&
                                    _.isEmpty(form.getFieldValue('setAsideCodes')) &&
                                    _.isEmpty(form.getFieldValue('opportunities')) &&
                                    _.isEmpty(form.getFieldValue('noticeTypes'))
                                }
                                type={'primary'}
                                htmlType="submit"
                                block
                            >
                                {ClassificationsLanguage.saveChange}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Space>
        </div>
    )
}
