import  { ActionType } from "./../resources/constants"

const { SET_USER, SET_ACCESS_TOKEN, SET_SOURCES } = ActionType

export default function GlobalReducer(state: any, action: { type: string, payload?: any }) {
    switch (action.type) {
        case SET_SOURCES:
            return setSources(state,action);
        case SET_USER:
            return setUser(state,action);
        case SET_ACCESS_TOKEN:
            return setAccessToken(state,action);
        default:
            return state;
    }
}

const setSources = (state: any, action: { type: string, payload?: any } ) => {
    const { sources } = action.payload;

    return {
        ...state,
        sources
    }
}

const setUser = (state: any, action: { type: string, payload?: any } ) => {
    const { user } = action.payload;

    return {
        ...state,
        user
    }
}

const setAccessToken = (state: any, action: { type: string, payload?: any } ) => {
    const { accessToken } = action.payload;

    return {
        ...state,
        accessToken
    }
}
