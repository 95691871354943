import React, { useContext, useEffect, useState } from 'react'
import { Space, Typography, App, Button, Flex, Modal, Avatar, Form, Tag, Divider } from 'antd'
import { Helmet } from "react-helmet"
import { FaRegUser, FaTrashAlt, FaPlus } from "react-icons/fa";
import { API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { Member } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

// Components
import InviteNewMember from './invite-new-member'

const { App: { title }, Pages: { Profile: { Members: MembersLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_MEMBER_ALL, USER_MANAGEMENT_PROFILE_MEMBER_REMOVE, DOWNLOAD } = API
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function Members() {

    const [members, setMembers] = useState<Member[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [form] = Form.useForm()
    const { user, accessToken} = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const { message, modal } = App.useApp()

    useEffect(() => {
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getMembers()
    }

    const getMembers = async () => axios.get(USER_MANAGEMENT_PROFILE_MEMBER_ALL).then((response: { data: Member[] }) => {
        const { data: members } = response
        setMembers(members)
    }, (error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]).then(response => response)
    })

    // @ts-ignore
    return (
        <div className={'members'}>
            <Helmet>
                <title>{title} - {MembersLanguage.title}</title>
            </Helmet>
            <Modal
                destroyOnClose={true}
                title={MembersLanguage.inviteNewMember}
                className={'ant-modal-invite-member'}
                open={openModal}
                cancelButtonProps={{
                    style: {display:'none'},
                }}
                okText={MembersLanguage.inviteMember}
                okButtonProps={{
                    block: true,
                    type: 'primary',
                }}
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields()
                    setOpenModal(false)
                }}
            >
                <InviteNewMember Form={Form} form={form} getMembers={getMembers} setOpenModal={setOpenModal} />
            </Modal>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Flex gap={'middle'} justify={'space-between'} style={{marginTop:50}}>
                    <Typography.Title level={1}>{MembersLanguage.title}</Typography.Title>
                    <Button type={'primary'} onClick={() => setOpenModal(true)}>
                        <Space><span>{MembersLanguage.addMember}</span><FaPlus size={12} /></Space>
                    </Button>
                </Flex>
                <Space direction="vertical" size={10} style={{width:'100%'}}>
                <Typography.Title level={2}>{members.length + 1} {MembersLanguage.members}</Typography.Title>
                <Divider />
                <div key={'admin'} className={'member'}>
                    <Space size={20}>
                        <Avatar size={75} icon={user?.avatar ? <img src={baseURL + DOWNLOAD + user?.avatar + `?width=75&height=75&token=${accessToken}`} width={75} /> : <FaRegUser size={25} />} />
                        <Space direction={'vertical'} size={5}>
                           <Space>
                               <Typography.Text>{user?.firstName} {user?.lastName}</Typography.Text>
                               <Tag color={'#fa98a9'}>{MembersLanguage.admin}</Tag>
                           </Space>
                            <Typography.Text className={'email'}>{user?.email}</Typography.Text>
                        </Space>
                    </Space>
                </div>
                <Divider />
                {
                    members.map(({ id, member, status, user }: Member, key) => (
                        <>
                            <div key={key} className={'member'}>
                                <Space size={20}>
                                    <Avatar size={75} icon={user?.avatar?.id ? <img src={baseURL + DOWNLOAD + user?.avatar.id + `?width=75&height=75&token=${accessToken}`} width={75} /> : <FaRegUser size={25} />} />
                                    <Space direction={'vertical'}>
                                        {user && <Typography.Text>{user?.firstName} {user?.lastName}</Typography.Text>}
                                        <Typography.Text className={'email'}>{user ? user?.email : member}</Typography.Text>
                                    </Space>
                                </Space>
                                <Typography.Text style={{fontSize:18}}>{
                                    // @ts-ignore
                                    EnumLanguage.Member[status]
                                }</Typography.Text>
                                <Button type="default" danger onClick={() => modal.confirm({
                                    className: 'ant-modal-members',
                                    title: Share.deleteConfirmMemberTitle,
                                    okText: Share.yes,
                                    okType: 'danger',
                                    cancelText: Share.no,
                                    onOk() {
                                        axios.delete(USER_MANAGEMENT_PROFILE_MEMBER_REMOVE + id).then(async () => {
                                            await getMembers()
                                            message.success(MessageLanguage[Message.DELETE_MEMBER]).then(response => response)
                                        }).catch((error: AxiosError) => {
                                            const { response: { data } } = error;
                                            // @ts-ignore
                                            message.error(MessageLanguage[data.message]).then(response => response )
                                        })
                                    }
                                })}><FaTrashAlt size={15} /><Space><span>{Share.delete}</span></Space></Button>
                            </div>
                            <Divider />
                        </>
                    ))
                }
                </Space>
            </Space>
        </div>
    )
}
