import React, { MouseEvent, useContext, useEffect, useState } from 'react'
import { Space, Typography, App, Upload, Button, Modal, Dropdown, Spin, Form, Table, Radio, Tag, Input } from 'antd'
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { Helmet } from "react-helmet"
import _ from "lodash";
import { FaTrashAlt, FaCloudUploadAlt, FaRegFileAlt, FaEllipsisV, FaDownload } from "react-icons/fa";
import { API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { AIFile, AIFilesForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"
import { ColumnsType } from "antd/es/table";
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

// Components
import Suggestions from './suggestions'

const { App: { title }, Pages: { Profile: { AIFiles: AIFilesLanguage }  }, Share, Enum: EnumLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_GET_AI_QUESTION, USER_MANAGEMENT_PROFILE_SET_AI_QUESTION, USER_MANAGEMENT_PROFILE_AI_FILE_ALL, USER_MANAGEMENT_PROFILE_AI_FILE_REMOVE, USER_MANAGEMENT_PROFILE_AI_FILE_ADD, DOWNLOAD } = API
const { Message, Budget, Grant } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function AiFiles() {

    const [aiFileId, setAIFileId] = useState<string|null>(null)
    const [aiFiles, setAIFiles] = useState<AIFile[]>([])
    const [certifications, setCertifications] = useState<string[]>([]);
    const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const { accessToken} = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const { message, modal } = App.useApp()

    const columns: ColumnsType<AIFile> = [
        {
            title: AIFilesLanguage.originalName,
            dataIndex: ['file', 'originalName'],
            key: 'originalName',
        },
        // {
        //     title: AIFilesLanguage.mimeType,
        //     dataIndex: ['file', 'mimeType'],
        //     key: 'mimeType',
        // },
        {
            title: AIFilesLanguage.size,
            dataIndex: ['file', 'size'],
            key: 'size',
            render: (size, record) => size>1024*1024 ? `${(size/1024/1024).toFixed(1)} MB` : `${(size/1024).toFixed(0)} KB`
        },
        {
            title: Share.operation,
            dataIndex: "operation",
            key: "operation",
            render: (text, { id, file: { id: fileId } }) => <Dropdown menu={{ items: [
                    {
                        key: 'view',
                        label: <Space style={{width:'100%'}} onClick={() => {
                            setAIFileId(id)
                            setOpenModal(true)
                        }}><FaRegFileAlt size={15} /><span>{AIFilesLanguage.suggestion}</span></Space>,
                    },
                    {
                        key: 'download',
                        label: <Typography.Link href={baseURL + DOWNLOAD + fileId + `?token=${accessToken}`}><Space><FaDownload size={15} /><span>{Share.download}</span></Space></Typography.Link>,
                    },
                    {
                        key: 'delete',
                        label: <Space style={{width:'100%'}} onClick={() => modal.confirm({
                            className: 'ant-modal-ai-files',
                            title: Share.deleteConfirmMemberTitle,
                            okText: Share.yes,
                            okType: 'danger',
                            cancelText: Share.no,
                            onOk() {
                                axios.delete(USER_MANAGEMENT_PROFILE_AI_FILE_REMOVE + id).then(async () => {
                                    await getAIFiles()
                                    message.success(MessageLanguage[Message.DELETE_MEMBER]).then(response => response)
                                }).catch((error: AxiosError) => {
                                    const { response: { data } } = error;
                                    // @ts-ignore
                                    message.error(MessageLanguage[data.message]).then(response => response )
                                })
                            }
                        })}><FaTrashAlt size={14} /><span>{Share.delete}</span></Space>,
                    },
                ] }} placement="bottomLeft"><a><FaEllipsisV /></a></Dropdown>
        }
    ]

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getAIFiles()
        await getAIQuestions()
    }

    const getAIFiles = async () => {
        setLoadingRecords(true)
        axios.get(USER_MANAGEMENT_PROFILE_AI_FILE_ALL).then((response: { data: AIFile[] }) => {
            const { data: aiFiles } = response
            setAIFiles(aiFiles)
            setLoadingRecords(false)
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
            setLoadingRecords(false)
        })
    }

    const getAIQuestions = async () => axios.get(USER_MANAGEMENT_PROFILE_GET_AI_QUESTION).then(async (response: { data: AIFilesForm }) => {
        const { data: { certifications = [], budget, grant } } = response
        setCertifications(certifications)
        budget && form.setFieldValue('budget', budget)
        grant && form.setFieldValue('grant', grant)
    }).catch( (error: {}) => error )

    const beforeUpload = (file: FileType) => {
        const isTxtOrPdfOrDoc = file.type === 'text/plain' || file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isTxtOrPdfOrDoc) {
            // @ts-ignore
            message.error(AIFilesLanguage.aiFileFormat).then(response => response );
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            // @ts-ignore
            message.error(AIFilesLanguage.aiFileSize).then(response => response );
        }
        return isTxtOrPdfOrDoc && isLt10M;
    };

    const onChange = async (info: { file: UploadFile, fileList: UploadFile[] }) => {
        const { file, fileList } = info
        const { status } = file;
        if (status === 'uploading') {
            setUploading(true)
        } else if (status === 'done') {
            const { response: aiFile } = file;
            setAIFileId(aiFile.id);
            setOpenModal(true)
            await getAIFiles()
            setUploading(false)
            message.success(MessageLanguage[Message.UPLOAD_SUCCESS]).then(response => response)
        } else if (status === 'error') {
            const { response } = file;
            setUploading(false)
            // @ts-ignore
            message.error(response?.message ? response.message : MessageLanguage[Message.UPLOAD_ERROR]).then(response => response)
        }
    };

    const setCertification = (certification: string) => {
        if (certification) {
            setCertifications(_.uniq([...certifications, certification]))
            form.resetFields(['certifications']);
        }
    }

    const onFinish = (values: AIFilesForm) => {
        const body: AIFilesForm = {
            certifications,
        }
        if (values.budget) body.budget = values.budget
        if (values.grant) body.grant = values.grant

        axios.post(USER_MANAGEMENT_PROFILE_SET_AI_QUESTION, body).then(async (response: {}) => {
            await getAIQuestions()
            message.success(MessageLanguage[Message.CHANGE_SUCCESS]).then(response => response)
        }, (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response)
        })
    };

    return (
        <div className={'ai-files'}>
            <Helmet>
                <title>{title} - {AIFilesLanguage.title}</title>
            </Helmet>
            <Modal
                className={'suggestions-modal'}
                width={850}
                destroyOnClose={true}
                title={AIFilesLanguage.suggestion}
                open={openModal}
                cancelButtonProps={{
                    style: {display:'none'},
                }}
                okText={AIFilesLanguage.save}
                okButtonProps={{
                    block: true,
                    type: 'primary',
                }}
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields()
                    setAIFileId(null)
                    setOpenModal(false)
                }}
            >
                { aiFileId && <Suggestions Form={Form} form={form} aiFileId={aiFileId} setOpenModal={setOpenModal} /> }
            </Modal>
            <Space direction="vertical" size={50} style={{width:'100%'}}>
                <Typography.Title level={1}>{AIFilesLanguage.title}</Typography.Title>
                <Spin spinning={uploading} tip={Share.loading}>
                    <div className={'upload-wrapper'}>
                        <Upload.Dragger
                            action={baseURL + USER_MANAGEMENT_PROFILE_AI_FILE_ADD}
                            headers={{ Authorization: `Bearer ${accessToken}` }}
                            multiple={false}
                            showUploadList={false}
                            disabled={uploading}
                            beforeUpload={beforeUpload}
                            onChange={onChange}
                        >
                            <div className="ant-upload-drag-icon"><FaCloudUploadAlt size={30} /></div>
                            <Typography.Title level={3} className="ant-upload-text">{AIFilesLanguage.upload}</Typography.Title>
                            <Typography.Title level={4} className="ant-upload-hint">{AIFilesLanguage.uploadDescription}</Typography.Title>
                        </Upload.Dragger>
                    </div>
                </Spin>
                <Typography.Title level={2}>{AIFilesLanguage.uploadRecently}</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={aiFiles}
                    pagination={false}
                    loading={loadingRecords}
                    scroll={{ x: true }}
                />
            </Space>
            <Form layout={'vertical'} form={form} name="ai-files" autoComplete="off" onFinish={onFinish} style={{marginTop:70}}>
                <Form.Item
                    label={AIFilesLanguage.budget}
                    name="budget"
                >
                    <Radio.Group>
                        <Radio value={Budget.Minus_Five_Hundred}>{EnumLanguage.Budget[Budget.Minus_Five_Hundred]}</Radio>
                        <Radio value={Budget.Five_Hundred_One_Million}>{EnumLanguage.Budget[Budget.Five_Hundred_One_Million]}</Radio>
                        <Radio value={Budget.One_Million_Plus}>{EnumLanguage.Budget[Budget.One_Million_Plus]}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={AIFilesLanguage.grant}
                    name="grant"
                >
                    <Radio.Group>
                        <Radio value={Grant.Minus_Five_Hundred}>{EnumLanguage.Budget[Grant.Minus_Five_Hundred]}</Radio>
                        <Radio value={Grant.Five_Hundred_One_Million}>{EnumLanguage.Grant[Budget.Five_Hundred_One_Million]}</Radio>
                        <Radio value={Grant.One_Million_Plus}>{EnumLanguage.Grant[Budget.One_Million_Plus]}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={AIFilesLanguage.certifications}
                    name="certifications"
                    extra={!_.isEmpty(certifications) && (
                        <div className={'tags'}>
                            {certifications.map((certification: string) => <Tag key={certification} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                const certificationsCopy = [...certifications]
                                certificationsCopy.splice(certificationsCopy.indexOf(certification), 1)
                                setCertifications(certificationsCopy)
                            }}><span className={'ellipsis'}>{certification}</span></Tag>)}
                        </div>
                    )}
                >
                    <Input
                        placeholder={AIFilesLanguage.certificationsPlaceholder}
                        size="large"
                        onBlur={({ target: { value: certification } }: any) => setCertification(certification)}
                    />
                </Form.Item>
                <Form.Item style={{marginTop:50}} shouldUpdate>
                    {() => (
                        <Button
                            disabled={
                                _.isEmpty(form.getFieldValue('budget')) &&
                                _.isEmpty(form.getFieldValue('grant')) &&
                                _.isEmpty(certifications)
                            }
                            type={'primary'}
                            htmlType="submit"
                            block
                        >
                            {AIFilesLanguage.saveChange}
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
