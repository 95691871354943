// Definition of Regex Constants

export default {
  isEmail: /^[_A-Za-z0-9]+(.[_A-Za-z0-9]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*(.[A-Za-z]{2,4})$/,
  isNumber: /^[0-9]*$/,
  notNumber: /^([0-9]*)$/,
  isWebsite: /^(?!https?:\/\/|www\.)[A-Za-z0-9-]{1,63}(?<!-)\.[A-Za-z]{2,63}(\.[A-Za-z]{2,63})*$/,
  isRole: /^[_A-Z]*$/,
  isPermission: /^[/.A-Za-z0-9]*$/,
};
