import enUS from 'antd/locale/en_US'
import _ from "lodash";

const content = {
    App: {
        title: "FelixFusion",
        description: "Your Comprehensive AI Solution for Sourcing, Managing, and Submitting Goverment Contracts.",
    },
    Commons: {
        Intro: {
            title: "FelixFusion AI",
            description: "Your Comprehensive AI Solution for Sourcing, Managing, and Submitting Goverment Contracts.",
        },
        PendingRequest: {
            loading: "Receiving information from the server.",
        }
    },
    Layouts: {
        Dashboard: {
            menu: "Menu",
            dashboard: "Dashboard",
            opportunities: "Opportunities",
            proposalAiAgent: "Proposal AI Agent",
            contractManagement: "Contract Management",
            schedules: "Schedules",
            pipelines: "Pipelines",
            templates: "Templates",
            knowledgeBase: "Knowledge Base",
            profile: "Profile",
            upgradePremium: "Upgrade to Premium",
            signOut: "Sign out",
            felixAIHistory: "Felix AI History",
            title: "FelixFusion",
            copyright: "© 2024 | All rights reserved for FelixFusion."
        },
        Public: {
            copyright: "© 2024 | All rights reserved for FelixFusion."
        }
    },
    Pages: {
        Dashboard: {
            title: "Powered by FelixFusion AI",
            description: "Choose your desired option",
            tenders: "Tenders",
            grants: "Grants",
            todayTenders: "Today Tenders",
            usTenders: "US Tenders",
            canadaTenders: "Canada Tenders",
            europeTenders: "Europe Tenders",
            payments: "Current Plan",
            plan: "Plan",
            activation: "Activation",
            amount: "Amount",
            expiration: "Expiration",
        },
        InviteMember: {
            titleSuccess: "Successfully Add to Members",
            descriptionSuccess: "Welcome to FlexFusion",
            backOpportunities: "Back to opportunities",
            titleError: "Error Add to Members",
            descriptionError: "Welcome to FlexFusion",
            backPayment: "Back to payment",
        },
        Payment: {
            title: "Unlock the power of AI",
            description: "Revolutionize Your Procurement with Automation.",
            prime: "Felix Prime",
            prime1: "Popular",
            prime2: "All Features of Felix Prime Plus",
            prime3: "Tailored Opportunities Selected Based on Your Profile",
            prime4: "Full-Service Tender Management",
            contactUs: "Contact Us",
            pro: "Felix Pro",
            pro1: "Per Month",
            pro2: "Unlimited User Assignment",
            pro3: "Customized AI Search Tool",
            pro4: "AI-Powered Operating System",
            pro5: "AI Insights & Indexes",
            pro6: "Contract Management Tool",
            payStripe: "Pay with Stripe",
            frequently: "Frequently asked questions",
            question1: "What is FelixFusion?",
            answer1: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question2: "How does FelixFusion work?",
            answer2: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question3: "What kind of questions can I ask FelixFusion?",
            answer3: "You can ask FelixFusion anything you would ask a human assistant, such as weather updates, news headlines, restaurant recommendations, and more.",
            question4: "Is my data safe with FelixFusion?",
            answer4: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question5: "Can I customize FelixFusion's responses?",
            answer5: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            question6: "Is FelixFusin available in multiple languages?",
            answer6: "FelixFusion is an AI-enhanced procurement platform that transcends traditional tools that target specific tasks. By offering a seamless, all-inclusive solution, FelixFusion facilitates every aspect of the procurement cycle, enhancing both efficiency and strategic insight.",
            successTitle: "Payment Successful",
            successDescription: "Thank you for completing your payment. This is a confirmation that we have just received your secure online payment. we sent you an email with a copy of your detailed invoice. Thank you!",
            successButton: "Complete Profile",
            errorTitle: "Payment Error",
            errorDescription: "Thank you for completing your payment. This is a confirmation that we have just received your secure online payment. we sent you an email with a copy of your detailed invoice. Thank you!",
            errorButton: "Back to Payment",
            buy: "Buy Subscription",
            pay1: "Pay $",
            pay2: " USD",
        },
        Opportunities: {
            title: "Opportunities",
            Filter: {
                title: "Filter By",
                useProfileFilter: "Use profile filter",
                source: "Default Source(s)",
                activeOnly: "Active Only",
                inactiveOnly: "Inactive Only",
                dueDate: "Due Date",
                postedDate: "Posted Date",
                solicitationNumber: "Solicitation Number",
                solicitationNumberPlaceholder: "Ex: W9132T21R0002",
                buyerInclude: "Buyer(s) Include",
                buyerExclude: "Buyer(s) Exclude",
                buyerPlaceholder: "Ex: Dept. of defense",
                naicsCode: "NAICS Code(s)",
                naicsCodePlaceholder: "Ex: 513210",
                pscCode: "PSC Code(s)",
                pscCodePlaceholder: "Ex: 2210",
                noticeType: "Notice Type(s)",
                noticeTypePlaceholder: "Ex: Solicitation",
                setAsideCode: "Set Aside Code(s)",
                setAsideCodePlaceholder: "Ex: 8A",
                applyFilter: "Apply Filter(s)",
                clearFilter: "Clear",
            },
            Search: {
                searchPlaceholder: "Search in all US Tenders",
                searchButton: "Search",
                export: "Export",
                filter: "Filter",
                createSchedule: "Create Schedule",
                selectOpportunity: "Select Opportunity",
                askAI: "Ask AI",
                buyer: "Buyer",
                naics: "NAICS",
                noticeType: "Notice Type",
                psc: "PSC",
                setAside: "Set Aside",
            },
            Tender: {
                active: 'Active',
                expire: 'Expire',
                askAlpha: 'Ask Alpha',
                addToCRM: 'Add to CRM',
                onDemandCredit: 'On Demand Credit',
                solicitationNumber: 'Solicitation Number',
                days: 'Days',
                overview: 'Overview',
                projectScopeWorkService: 'Project Scope of Work or Service',
                billOfMaterials: 'Bill of Materials',
                item: 'Item',
                quantity: 'Quantity',
                preferredVendor: 'Preferred Vendor',
                modelNumber: 'Model Number',
                technicalSpecification: 'Technical Specification',
                additionalTechnicalInformation: 'Additional Technical Information',
                vendorSelectionCriteria: 'Vendor Selection Criteria',
                delivery: 'Delivery',
                deliveryAddress: 'Delivery Address',
                placePerformance: 'Place of Performance',
                buyer: 'Buyer',
                nameAgency: 'Name of the Agency',
                subTierName: 'Sub-Tier Name',
                officeName: 'Office Name',
                contactInformation: 'Contact Information',
                name: "Name",
                email: "Email",
                phone: "Phone",
                buyerWebsite: 'Buyer Website',
                inspectionTest: 'Inspection & Test',
                specificTestsRequested: 'Specific Tests Requested',
                inspectionsRequested: 'Inspections Requested',
                additionalRequiredSteps: 'Additional Required Steps',
                compliance: 'Compliance',
                setAsideInformation: 'Set-Aside Information',
                requiredCertificates: 'Required Certificates',
                warrantyDetails: 'Warranty Details',
                guaranteeDetails: 'Guarantee Details',
                legalClauses: 'Legal Clauses',
                clauseNumber: 'Clause Number',
                clauseTitle: 'Clause Title',
                description: 'Description',
                commercialInsight: 'Commercial Insight',
                estimatedPrice: 'Estimated Pricee',
                estimatedPricePartOne: 'A competitive total offer near',
                estimatedPricePartTwo: 'would align with market standards while ensuring profitability.',
                docs: 'Docs',
                tenderFiles: 'Tender Files',
                bidSubmissionFormat: 'Bid Submission Format',
                requirement: 'Requirement',
                decisionIntelligence: 'Decision Intelligence (Beta)',
                strategicAlignment: '1. Strategic Alignment',
                relevanceExpertise: 'Relevance of Expertise',
                valueProposition: 'Value Proposition',
                outcomeAdvantage: 'Outcome Advantage',
                opportunityPrioritization: '2. Opportunity Prioritization',
                setAsideOpportunity: 'Set-Aside Opportunity',
                evaluationFocus: 'Evaluation Focus',
                timelineSubmission: 'Timeline for Submission',
                riskMitigation: '3. Risk Mitigation',
                complianceRisks: 'Compliance Risks',
                deliveryRisks: 'Delivery Risks',
                financialRisks: 'Financial Risks',
                complianceCertification: '4. Compliance & Certification',
                certifications: 'Certifications',
                documentation: 'Documentation',
                regulatory: 'Regulatory',
                competitivePositioning: '5. Competitive Positioning',
                keyDifferentiators: 'Key Differentiators',
                smallBusinessAdvantage: 'Small Business Advantage',
                actionPlanForYourBusiness: '6. Action Plan for Your Business',
                alignStrengthsWithRequirements: '1. Align Strengths with Requirements',
                optimizeProposalDocumentation: '2. Optimize Proposal Documentation',
                confirmSAMRegistration: '3. Confirm SAM Registration',
                refinePricingStrategy: '4. Refine Pricing Strategy',
                logisticsPlanning: '5. Logistics Planning',
                proactiveCommunication: '6. Proactive Communication',
                loadingTender: 'Please wait for a few minutes and do not perform any operations until the page is fully loaded.',
            },
        },
        SignIn: {
            title: "Sign in",
            google: "Continue with Google",
            signIn: "Sign in",
            signUp: "Create account",
            or: "OR",
            email: "Email",
            emailPlaceholder: "Enter your mail",
            password: "Password",
            passwordPlaceholder: "type your password here...",
            forgotPassword: "Forgot Password?",
            otp: "OTP",
            enableOTP: "Enable OTP",
            sendOTP: "Send OTP Code",
            submit: "Sign in with FelixFusion",
        },
        ResetPassword: {
            title: "Reset your password",
            email: "Email",
            emailPlaceholder: "Enter your mail",
            password: "Password",
            passwordPlaceholder: "type your password here...",
            passwordConfirm: "Confirm Password",
            passwordConfirmPlaceholder: "type confirm your password here...",
            resetPassword: "Reset Password",
            signIn: "Back to Sign In",
            resetPasswordNotice: "Your password has been successfully reset.",
            sendEmailNotice: "If that email address is in our database, we will send you an email to reset your password.",
            gmail: "Open Gmail",
            outlook: "Open Outlook",
            yahoo: "Open Yahoo!",
        },
        Profile: {
            title: "Profile",
            myAccount: "My Account",
            felixTechnology: "Felix Technology",
            overview: "Overview",
            classifications: "Preferences",
            aiFiles: "AI Questions",
            members: "Team",
            paymentHistory: "Payments",
            deleteAccount: "Delete account",
            MyAccount: {
                title: "My Account",
                information: "Personal Information",
                uploadTitle: "Upload New Image",
                uploadDescription: "At least 800x800 px recommended. JPG or PNG is allowed",
                avatar: "Avatar",
                avatarFormat: "You can only upload JPG/PNG file!",
                avatarSize: "Avatar image must smaller than 2MB!",
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email",
                password: "Old Password",
                newPassword: "New Password",
                newPasswordConfirm: "Confirm New Password",
                passwordDescription: "Minimum 8 characters",
                otp: "OTP",
                enableOTP: "Enable OTP",
                saveChange: "Save change",
                cancel: "Cancel",
            },
            Overview: {
                title: "Overview",
                organizationName: "Organization Name",
                organizationNamePlaceholder: "Type your organization name here",
                yourWebsite: "Your Website",
                yourWebsitePlaceholder: "EX: domain.com",
                description: "Description",
                descriptionPlaceholder: "A short description about your organization",
                uei: "UEI",
                ueiPlaceholder: "Type your UEI here",
                cageCode: "CAGE Code",
                cageCodePlaceholder: "Type your CAGE Code here",
                contactInformation: "Contact Information",
                firstName: "First Name",
                firstNamePlaceholder: "Type your First Name here",
                lastName: "Last Name",
                lastNamePlaceholder: "Type your Last Name here",
                email: "Email",
                emailPlaceholder: "Type your Email here",
                address: "Address",
                address1: "Address Line 1",
                address1Placeholder: "Type your address line 1 here",
                address2: "Address Line 2",
                address2Placeholder: "Type your address line 2 here",
                city: "City",
                cityPlaceholder: "Type your city here",
                state: "State / Province",
                statePlaceholder: "Type your state here",
                zipCode: "Postal / ZIP Code",
                zipCodePlaceholder: "Type your postal/zip code here",
                country: "Country",
                countryPlaceholder: "Select your country",
                saveChange: "Save change",
                cancel: "Cancel",
            },
            Classifications: {
                title: "Preferences",
                naicsCode: "NAICS Code(s)",
                naicsCodePlaceholder: "Ex: 513210",
                pscCode: "PSC Code(s)",
                pscCodePlaceholder: "Ex: 2210",
                noticeType: "Notice Type(s)",
                noticeTypePlaceholder: "Ex: Solicitation",
                setAsideCode: "Set Aside Code(s)",
                setAsideCodePlaceholder: "Ex: 8A",
                opportunity: "Preferred Opportunities",
                buyer: "Buyer Preferences",
                buyerIncludePlaceholder: "Buyer(s) Include",
                buyerExcludePlaceholder: "Buyer(s) Exclude",
                job: "Are you manufacturer or trader?",
                companySize: "What is the size of your company?",
                tenderType: "Type of preferred tenders",
                saveChange: "Save change",
                cancel: "Cancel",
            },
            AIFiles: {
                title: "AI Questions",
                aiFileFormat: "You can only upload TXT/PDF/DOC file!",
                aiFileSize: "You file must smaller than 10MB!",
                upload: "Upload to FelixFusion",
                uploadDescription: "You can add prompts after uploading. TXT/PDF/DOC",
                uploadRecently: "Recently Uploaded",
                mimeType: "File Type",
                originalName: "Document Name",
                size: "File Size",
                budget: "What is your budget for tender opportunities?",
                grant: "How much grant funding are you seeking?",
                certifications: "Do you have any specific certifications?",
                certificationsPlaceholder: "Type your certificate name here",
                suggestion: "Suggestions",
                pscCode: "PSC Code(s)",
                pscCodePlaceholder: "Ex: 2210",
                pscCodeSelected: "Selected PSC Codes (max 50 codes)",
                pscCodeSuggested: "Suggested PSC Codes",
                noticeType: "Notice Type(s)",
                noticeTypePlaceholder: "Ex: Solicitation",
                noticeTypeSuggested: "Suggested Notice Type(s)",
                setAsideCode: "Set Aside Code(s)",
                setAsideCodePlaceholder: "Ex: 8A",
                setAsideCodeSuggested: "Suggested Set Aside Code(s)",
                naicsCodeSelected: "Selected NAICS Codes (max 50 codes)",
                naicsCodeSuggested: "Suggested NAICS Codes",
                naicsCode: "NAICS Code(s)",
                naicsCodePlaceholder: "Ex: 513210",
                buyerInclude: "Buyer Include",
                buyerExclude: "Buyer Exclude",
                buyerIncludePlaceholder: "Buyer(s) Include",
                buyerExcludePlaceholder: "Buyer(s) Exclude",
                buyersIncludeSelected: "Selected Buyers Include",
                buyerIncludeSuggested: "Suggested Buyers Include",
                buyersExcludeSelected: "Selected Buyers Exclude",
                buyerExcludeSuggested: "Suggested Buyers Exclude",
                save: "Save and Update Profile",
                saveChange: "Save change",
                cancel: "Cancel",
            },
            Members: {
                title: "Members",
                members: "members",
                admin: "Admin",
                email: "Email",
                emailPlaceholder: "EX: info@domain.com",
                emailHelp: "You can only use your organization email.",
                inviteMember: "Invite Member",
                inviteNewMember: "Invite New Member",
                addMember: "Add Member",
            },
            PaymentHistory: {
                title: "Payment History",
                plan: "Plan",
                activation: "Activation",
                amount: "Amount",
                expiration: "Expiration",
                backOpportunities: "Back to opportunities",
            },
            DeleteAccount: {
                title: "We’re sorry to see you go :(",
                description: "Warning: Deleting your account will permanently remove all of your data and cannot be undone. This includes your profile, AI chat histories, Contracts, and any other information associated with your account. Are you sure you want to proceed with deleting your account?",
                password: "Your Password",
                passwordPlaceholder: "Password",
                delete: "Delete my account",
                cancel: "Cancel",
            },
        },
        SignUp: {
            title: "Welcome to FlexFusion",
            google: "Continue with Google",
            signIn: "Sign in",
            signUp: "Create account",
            or: "OR",
            firstName: "First Name",
            firstNamePlaceholder: "John",
            lastName: "Last Name",
            lastNamePlaceholder: "Doe",
            email: "Email",
            emailPlaceholder: "Enter your mail",
            password: "Password",
            passwordPlaceholder: "type your password here...",
            passwordDescription: "At least 8 characters",
            termsOfService: "Terms of Service",
            agree: "Agree",
            disagree: "Disagree",
            termsOfService_1: "By creating an account, you agree to our",
            termsOfService_2: "Terms of Service and Privacy",
            termsOfService_3: "& Cookie Statement.",
            submit: "Join FlexFusion",
        },
        NotFound: {
            title: "Not Found",
            description: "Unfortunately, the page you requested was not found.",
        },
        Forbidden: {
            title: "Not Authorization",
            description: "Unfortunately, you do not have permission to access this page.",
        },
    },
    Validation: {
        required: "Required !",
        length: "Length",
        pattern: "Does not match the pattern",
        number: "can only be a number",
        string: "can only be a string",
        website: "invalid domain",
        min: "Min",
        max: "Max",
        invalidEmail: "Invalid email address",
        passwordConfirm: "The new password that you entered do not match!",

    },
    Share: {
        edit: "Edit",
        add: "Add",
        delete: "Delete",
        view: "View",
        save: "Save",
        cancel: "Cancel",
        search: "Search",
        yes: "Yes",
        no: "No",
        row: "Row",
        noResults: "No Search Results Found",
        operation: "Operation",
        prev: "Prev",
        next: "Next",
        download: "Download",
        pleaseSelect: "Please Select ...",
        selectDate: "Select Date",
        paginationShowTotal: "Show total",
        deleteConfirmMemberTitle: "Are you sure to delete member?",
        deleteConfirmTitle: "Are you sure to delete the desired item?",
        deleteConfirmOk: "Ok",
        deleteConfirmCancel: "Cancel",
        attachment: "File upload location",
        attachmentDescription: "Attach your files by clicking or dropping them in this section.",
        loading: "Loading ...",
        backHome: "Back to home",
        usd: "USD",
    },
    Message: {
        SIGN_IN_PROFILE: "You are logged in to your account.",
        SIGN_OUT_PROFILE: "You are logged out of your account.",
        UPDATE_PROFILE: "Your account information has been successfully change.",
        CAPTCHA_INVALID: "Captcha invalid",
        UPLOAD_SUCCESS: "File uploaded successfully",
        UPLOAD_ERROR: "There was an error loading the file",
        CHANGE_SUCCESS: "The changes were applied successfully",
        DELETE_MEMBER: "Member delete successfully",
        ADD_MEMBER: "Member add successfully",
        INVITE_MEMBER: "Invite Member successfully",
        DELETE_ACCOUNT: "Delete account successfully",
        OTP_SUCCESS: "OTP Code send to email successfully",
        USER_OTP_IS_DISABLE: "OTP disabled for this email",
        OTP_CODE_SENT: "OTP Code already sent (It is not possible to send until 10 minutes)",
        "ThrottlerException: Too Many Requests": "Too Many Requests",
        "Unauthorized": "The entered information is not verified.",
        "Not Found": "There is no such record.",
        "duplicate email user": "duplicate email address",
        "reset password token invalid": "reset password token invalid",
        "file is not uploaded": "file is not uploaded",
        "old password invalid": "old password invalid",
        "remove user invalid": "It is not possible to delete this member",
        "add user invalid": "It is not possible to add this user",
        "organization name invalid": "Organization Name invalid",
        "password invalid": "Your Password invalid",
        "It is impossible to invite this user": "It is impossible to invite this user",
    },
    Enum: {
        Plan: {
            FELIX_PRO: "Felix Pro",
            FELIX_PRIME: "Felix Prime",
        },
        Member: {
            MEMBER: "Member",
            INVITE: "Invite",
        },
        Job: {
            MANUFACTURER: 'I’m a Manufacturer',
            TRADER: 'I’m a Trader',
        },
        CompanySize: {
            ZERO_TEN: '0-10',
            TEN_FIFTY: '10-50',
            FIFTY_PLUS: '+50',
        },
        TenderType: {
            GOODS: 'Goods',
            SERVICE: 'Service',
            BOTH: 'Both',
        },
        Budget: {
            MINUS_FIVE_HUNDRED: 'Less than 500k',
            FIVE_HUNDRED_ONE_MILLION: '500k-1M',
            ONE_MILLION_PLUS: 'More than 1M',
        },
        Grant: {
            MINUS_FIVE_HUNDRED: 'Less than 500k',
            FIVE_HUNDRED_ONE_MILLION: '500k-1M',
            ONE_MILLION_PLUS: 'More than 1M',
        },
        Type: {
            US_TENDER: 'US Tenders',
            US_GRANT: 'US Grants',
            EUROPE_TENDER: 'Europe Tenders',
            EUROPE_GRANT: 'Europe Grants',
            CANADA_TENDER: 'Canada Tenders',
            CANADA_GRANT: 'Canada Grants',
        },
    }
}

export default _.merge(enUS, content);
