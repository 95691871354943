import React, { useState, useContext } from 'react'
import { Layout, Drawer, Flex, Button, Affix } from 'antd'
import { isMobileOnly, isDesktop } from "react-device-detect"
import { MdOutlineMenuOpen, MdOutlineMenu } from "react-icons/md";
import { FaTimes, FaBars } from "react-icons/fa"
import language from "../../resources/languages/en_US";

// Components
import Content from './content'
import Footer from './footer'
import Menu from './menu'

const { Layouts: { Dashboard } } = language
const { Sider } = Layout

export default function Index() {
    const [collapsed, setCollapsed] = useState(!isMobileOnly)

    return (
        <Layout className='dashboard-layout'>
            {
                isMobileOnly ? (
                    <Drawer
                        className={'sider-drawer'}
                        title={Dashboard.menu}
                        closeIcon={<FaTimes size={19} />}
                        placement="left"
                        width="100%"
                        zIndex={9999}
                        open={collapsed}
                        onClose={() => setCollapsed(!collapsed)}
                    >
                        <Menu collapsed={collapsed} setCollapsed={setCollapsed} />
                    </Drawer>
                ) : (
                    <Sider
                        className={'sider-desktop'}
                        width={250}
                        theme="light"
                        trigger={null}
                        collapsible
                        collapsed={collapsed}
                    >
                        <Flex vertical={true} justify={'center'} align={collapsed?'center':'normal'}>
                            <div className={'collapsed-wrapper'}>
                                { collapsed ? null : <img alt={Dashboard.title} src={require('../../resources/media/logo-white.png')} width={100} /> }
                                <Button
                                    style={{left:collapsed?0:15}}
                                    type={'text'}
                                    icon={collapsed ? <MdOutlineMenuOpen size={32} /> : <MdOutlineMenu size={32} />}
                                    onClick={() => setCollapsed(!collapsed)}
                                    className='collapsed'
                                />
                            </div>
                            <Menu collapsed={collapsed} />
                        </Flex>
                    </Sider>
                )
            }
            <Layout className={'layout-auth'}>
                {
                    isMobileOnly ? collapsed ? null : (
                        <div style={{position:'absolute',left:0}}>
                            <Affix offsetTop={15}>
                                <Button
                                    icon={collapsed ? <FaTimes /> : <FaBars />}
                                    onClick={() => setCollapsed(!collapsed)}
                                    className='collapsed-mobile'
                                />
                            </Affix>
                        </div>
                    ) : null
                }
                <Content />
                <Footer />
            </Layout>
        </Layout>
    )
}
