import React, { createContext, Dispatch  } from 'react';

// Interfaces
import { Source } from "../interfaces/pages/opportunities";

export default createContext<{ user: { avatar: string|null, firstName?: string, lastName?: string, email: string|null, otp: boolean, roles: string[] }|null, accessToken: null|string, sources: Source[], dispatch: Dispatch<{ type: string, payload: object }> }>({
    user: { avatar: null, email: null, otp: false, roles: [] },
    accessToken: null,
    sources: [],
    dispatch: () => {}
})
