import React from 'react'
import { useNavigate } from "react-router-dom"
import { Result, Button } from 'antd'
import { Helmet } from 'react-helmet'
import language from "../resources/languages/en_US"

const { App: { title }, Pages: { NotFound }, Share } = language

export default function Index() {

    const navigate = useNavigate()

    return (
        <div className={'not-found'}>
            <Helmet>
                <title>{title} - {NotFound.title}</title>
            </Helmet>
            <Result
                status="404"
                title="404"
                subTitle={NotFound.description}
                extra={<Button type="link" className={'link-button'} onClick={() => navigate('/')}><span className={'white'}>{Share.backHome}</span></Button>}
            />
        </div>
    )
}
